export const levels = [
  {
    id: "1",
    location: "🌎 South America",
    countries: [
      {
        id: "venezuela",
        name:"Venezuela",
        flag: "venezuela.png",
        silhouette: "venezuela.png",
        clue: "Caracas",
      },
      {
        id: "colombia",
        name:"Colombia",
        flag: "colombia.png",
        silhouette: "colombia.png",
        clue: "Bogotá",
      },
      {
        id: "ecuador",
        name:"Ecuador",
        flag: "ecuador.png",
        silhouette: "ecuador.png",
        clue: "The Equator",
      },
      {
        id: "peru",
        name:"Peru",
        flag: "peru.png",
        silhouette: "peru.png",
        clue: "Machu Picchu",
      },
      {
        id: "brazil",
        name:"Brazil",
        flag: "brazil.svg",
        silhouette: "brazil.png",
        clue: "Is the largest country in both South America and Latin America",
      },
      {
        id: "chile",
        name:"Chile",
        flag: "chile.png",
        silhouette: "chile.png",
        clue: "Borders with Argentina",
      },
    ],
  },
  {
    id: "2",
    location: "🌍 Southern Europe",

    countries: [
      {
        id: "italy",
        name:"Italy",
        flag: "italy.png",
        silhouette: "italy.png",
        clue: "Toto Cutugno",
      },
      {
        id: "spain",
        name:"Spain",
        flag: "spain.png",
        silhouette: "spain.png",
        clue: "Paco de Lucía",
      },
      {
        id: "croatia",
        name:"Croatia",
        flag: "croatia.png",
        silhouette: "croatia.png",
        clue: "Zagreb",
      },
      {
        id: "portugal",
        name:"Portugal",
        flag: "portugal.png",
        silhouette: "portugal.png",
        clue: "Cristiano Ronaldo",
      },
      {
        id: "greece",
        name:"Greece",
        flag: "greece.png",
        silhouette: "greece.png",
        clue: "Philosophy",
      },
      {
        id: "albania",
        name:"Albania",
        flag: "albania.svg",
        silhouette: "albania.png",
        clue: "Two eagles",
      },
    ],
  },
  {
    id: "3",
    location: "🌏 Eastern Europe",

    countries: [
      {
        id: "russia",
        name:"Russia",
        flag: "russia.png",
        silhouette: "russia.png",
        clue: "The largest country in the world",
      },
      {
        id: "poland",
        name:"Poland",
        flag: "poland.png",
        silhouette: "poland.png",
        clue: "Germany invaded this country in 1939",
      },
      {
        id: "turkey",
        name:"Turkey",
        flag: "turkey.png",
        silhouette: "turkey.png",
        clue: "Has cultural connections with the ancient Greek, Persian, Roman, Byzantine and Ottoman empires",
      },
      {
        id: "ukraine",
        name:"Ukraine",
        flag: "ukraine.png",
        silhouette: "ukraine.png",
        clue: "Grivna",
      },
      {
        id: "romania",
        name:"Romania",
        flag: "romania.png",
        silhouette: "romania.png",
        clue: "Transylvania",
      },
      {
        id: "armenia",
        name:"Armenia",
        flag: "armenia.png",
        silhouette: "armenia.png",
        clue: "Is bounded by Georgia and Azerbaijan, while its neighbours to the southeast and west are, respectively, Iran and Turkey",
      },
    ],
  },
];
